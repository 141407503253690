
import { defineComponent } from "vue";
import store from '@/store/index';
import { PresenceController, SessionController, TimeEntryController, WorkbreakController } from "@/controller/";
import { Employee, Presence, TimeEntry, Workbreak } from "@/model";
import { IconButton } from '@/ui';
import { TimeEntryCard }  from "@/components";
import { Converter, DateTimeUtils } from "@/utils";

export default defineComponent({
    name: "DailyReport",
    components: { TimeEntryCard, IconButton },
    props: {
        timeentries: {
            type: Array,
            default: null
        }
    },
    data(){
        return{
            store,
            sessionEmployee: {} as Employee,
            timeEntries: [] as Array<TimeEntry>,
            //activePresence: {} as Presence,
            presences: [] as Array<Presence>,
            workbreaks: [] as Array<Workbreak>,
            activeDay: Math.floor(Date.now() / 1000)
        }
    },
    created(){
        this.getSessionUser();
    },
    computed: {
        timeSum() {
            let sum = 0;
            this.timeEntries.forEach((entry : any) => {
                sum += entry.getTimeGone()
            })
            return sum;
        },
        latestInteraction(){
            let last = 0;
            this.timeEntries.forEach((entry : any) => {
                if (entry.tstamp > last) {
                    last = entry.tstamp;
                }
            })
            return this.convertToTime(last);
        },
        activePresence() {
            return this.presences.length ? this.presences[this.presences.length-1] : null
        },
        presenceTime() {
            const aP = (this.activePresence as Presence)
            if (aP?.go) {
                return aP?.go - aP?.come
            } else {
                return Math.floor(new Date().valueOf() / 1000) - aP?.come
            }
        },
        displayActiveDay() : string {
            return DateTimeUtils.convertTimeStampToDate(this.activeDay, true)
        },
    },
    methods: {
        convertToTime(ts:number){
            return Converter.convertTimeStampToTime(ts)
        },
        convertTimeStampToTime(ts: number) {
            return Converter.convertTimeStampToTime(ts)
        },
        convertSecondsToTime(ts:number){
            return Converter.convertSecondsToTime(ts)
        },
        convertSeconds(ts: any) {
            return DateTimeUtils.convertSecondsToTime(ts)
        },

        getSessionUser(){
            //this.sessionUser = await SessionController.sessionUser 
            this.sessionEmployee = SessionController.sessionEmployee;
            //this.getActivePresence();
            this.getEmployeeData()
        },
        getEmployeeData() {
            this.getPresence()
            this.getTimeEntries()
            this.getWorkbreaks()
        },
            //event handler
        handleSwitchDay(direction: any) {
            this.activeDay += 86400*direction
            const midnights = DateTimeUtils.getMidnightToMidnight(new Date())
            if (this.activeDay > midnights.end) {
                this.activeDay -= 86400;
            } else {
                //this.getTimeEntries()
                this.getEmployeeData()
            }
        },               
        async getTimeEntries() {
            const day = DateTimeUtils.getDayNoonTimestamp(new Date(this.activeDay*1000))
            const res = await TimeEntryController.fetchDayTimeEntries(this.sessionEmployee.uid, day)
            if(!res.error){
                this.timeEntries = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        /* async getActivePresence(){
            this.activePresence = await PresenceController.getActivePresence(this.sessionEmployee.uid)
        }, */
        async getPresence() {
            const date = new Date()
            date.setTime(this.activeDay*1000)
            //const day = DateTimeUtils.getMidnightToMidnight(date)
            const day = DateTimeUtils.getDayNoonTimestamp(date)
            const res = await PresenceController.getPresences(day, day, this.sessionEmployee.uid);
            if(!res.error){
                this.presences = res.items;
            } else {
                this.presences = []
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }             
        },
        async getWorkbreaks() {
            const day = DateTimeUtils.getDayNoonTimestamp(new Date(this.activeDay*1000));
            const response = await WorkbreakController.getWorkbreaks(day, day, this.sessionEmployee.uid);
            if (response.items) {
                this.workbreaks = response.items
            }
            console.log('getWorkbreaks', this.workbreaks)
        }

    },
})
